/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const {Table} = Object.assign({}, _provideComponents(), props.components);
  if (!Table) _missingMdxReference("Table", true);
  return React.createElement(Table, null, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Faroese"), "\n", React.createElement("th", null, "Transliteration"), "\n", React.createElement("th", null, "English"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "sunnudagur"), "\n", React.createElement("td", null, "sunn-uuh-dae-wur"), "\n", React.createElement("td", null, "Sunday")), React.createElement("tr", null, React.createElement("td", null, "mánadagur"), "\n", React.createElement("td", null, "maah-nah-dae-wur"), "\n", React.createElement("td", null, "Monday")), React.createElement("tr", null, React.createElement("td", null, "týsdagur"), "\n", React.createElement("td", null, "tuysh-dae-wur"), "\n", React.createElement("td", null, "Tuesday")), React.createElement("tr", null, React.createElement("td", null, "mikudagur / ónsdagur"), "\n", React.createElement("td", null, "mee-kuu-dae-wur / ons-dae-wur"), "\n", React.createElement("td", null, "Wednesday")), React.createElement("tr", null, React.createElement("td", null, "hósdagur / tórsdagur"), "\n", React.createElement("td", null, "hoes-dae-wur / toas-dae-wur"), "\n", React.createElement("td", null, "Thursday")), React.createElement("tr", null, React.createElement("td", null, "fríggjadagur"), "\n", React.createElement("td", null, "fruydj-ah-dae-wur"), "\n", React.createElement("td", null, "Friday")), React.createElement("tr", null, React.createElement("td", null, "leygardagur"), "\n", React.createElement("td", null, "lay-yar-dae-wur"), "\n", React.createElement("td", null, "Saturday"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
